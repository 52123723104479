import { Box, Typography, Button, useMediaQuery } from '@mui/material';

interface LetsGetStartedSectionProps {
  h1Style: any;
  paragraphStyle: any;
}

const LetsGetStartedSection: React.FC<LetsGetStartedSectionProps> = ({ h1Style, paragraphStyle }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isSmall = useMediaQuery('(max-width:1070px)');
  return (
    <Box style={{ position: 'relative', overflow: 'visible', width: '100%'}} sx={{ mt: isMobile ? 5 : 10 }}>
      <Box
        component="img"
        src="LetsGetStarted.jpg"
        alt="Home Page Top Banner"
        sx={{
          display: 'block',
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          top: 0,
          right: 0,
          width: {xs:'60%', md:'60%'},
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          ml: 4,
          pt: { xs: 2, md: 0 },
          color: 'white',
        }}
      >
        <Typography
          component="h2"
          variant="h1"
          sx={{
            textAlign: 'center',
            mb: 1,
            ...h1Style,
            mt:{xs: 0, md:5},
            mr:{xs: 1, md:3},
          }}
        >
          Excited to work with Bit Blossom Software to bring your vision to life?
        </Typography>

        <Typography
          component="p"
          sx={{
            textAlign: 'center',
            ...paragraphStyle,
            color: 'white',
            mr:{xs: 0, md:3},
            mt:{xs: 0, md:2},
            display: { xs: 'none', md: 'block' },
          }}
        >
          I can&rsquo;t wait to help you simplify technology and experience the confidence that comes with a stunning website, custom software, or seamless IT solutions.
        </Typography>

        <Button
          variant="contained"
          color="primary"
          sx={{
            alignSelf: 'center',
            mt: {xs: 0,  md: isSmall ? 5 : 20},
            px: 2,
            py: 1,
            fontSize: { xs:'0.5rem', md:'1.2rem'},
          }}
          onClick={() => window.location.href = '/contact'}
        >
          Let&rsquo;s Get Started
        </Button>
      </Box>
    </Box>
  );
}


export default LetsGetStartedSection;
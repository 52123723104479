import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

export interface stepText {
  title: string;
  description: string;
}

interface ProcessSectionProps {
  h1Style: any;
  stepText: stepText[];
}

const StepTitleStyle = {
  fontSize: {
    xs: '18px',
    sm: '22px',
    md: '28px',
  },
  fontFamily: 'DM Sans',
  lineHeight: {
    xs: 1,
    md: 1.5,
    lg: 1.5,
  },
  color: 'primary.main',
  fontWeight: 'bold',
  mb: 1,
};

const StepTextStyle = {
  fontFamily: 'DM Sans',
  fontSize: {
    xs: '14px',
    sm: '18px',
    md: '20px',
  },
  lineHeight: 1.1,
  color: 'primary.main',
  opacity: 0.8,
};

const lineWeight = 3;

interface MotionWrapperProps {
  children: React.ReactNode;
  isSmallBreakpoint: boolean;
}

const MotionWrapper: React.FC<MotionWrapperProps> = ({ children, isSmallBreakpoint }) => {
  return isSmallBreakpoint ? (
    <>{children}</>
  ) : (
    <motion.div whileHover={{ scale: 1.1 }} transition={{ duration: 0.3 }}>
      {children}
    </motion.div>
  );
};

const ProcessSection: React.FC<ProcessSectionProps> = ({ h1Style, stepText }) => {
  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        textAlign: 'center',
        pt: { sm: 10, md:0},
        ml: { sm: 0, md: 1 },
        mr: { sm: 0, md: 1 },
        pr: 4,
        pl: 4,
        mb: { xs: 0, md: 10 },
      }}
    >
      <Typography variant="h4" sx={{ ...h1Style, color: 'primary.main', mb: 5 }}>
        Our Process
      </Typography>

      {isSmallBreakpoint ? (
        // Mobile layout: one column in proper order (0, 1, 2, 3)
        <Grid container direction="column" spacing={2}>
          {stepText.map((step, index) => (
            <Grid key={index}>
              <MotionWrapper isSmallBreakpoint={isSmallBreakpoint}>
                <Typography sx={{ ...StepTitleStyle }}>{step.title}</Typography>
                <Typography sx={StepTextStyle}>{step.description}</Typography>
              </MotionWrapper>
              {index !== stepText.length - 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box
                    sx={{
                      borderLeft: lineWeight,
                      borderColor: 'secondary.main',
                      height: '50px',
                      mt: 1,
                    }}
                  />
                </Box>
              )}
            </Grid>
          ))}
        </Grid>
      ) : (
        // Desktop layout: original two-row grid layout
        <>
          <Grid container>
            <Grid
              size={{ sm: 5, md: 4 }}
              sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            >
              <MotionWrapper isSmallBreakpoint={isSmallBreakpoint}>
                <Typography sx={{ ...StepTitleStyle }}>{stepText[0].title}</Typography>
                <Typography sx={StepTextStyle}>{stepText[0].description}</Typography>
              </MotionWrapper>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                  sx={{
                    borderLeft: lineWeight,
                    borderColor: 'secondary.main',
                    height: '50px',
                    mt: 1,
                  }}
                />
              </Box>
            </Grid>
            <Grid size={{ sm: 1, md: 2 }} />
            <Grid
              size={{ sm: 5, md: 4 }}
              sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            >
              <MotionWrapper isSmallBreakpoint={isSmallBreakpoint}>
                <Typography sx={{ ...StepTitleStyle }}>{stepText[2].title}</Typography>
                <Typography sx={StepTextStyle}>{stepText[2].description}</Typography>
              </MotionWrapper>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                  sx={{
                    borderLeft: lineWeight,
                    borderColor: 'secondary.main',
                    height: '50px',
                    mt: 1,
                  }}
                />
              </Box>
            </Grid>
            <Grid size={{ sm: 1, md: 2 }} />
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 0 }}>
            <Box sx={{ borderBottom: lineWeight, borderColor: 'secondary.main', width: '100%' }} />
          </Box>
          <Grid container spacing={{ sm: 1 }}>
            <Grid size={{ sm: 1, md: 2 }} />
            <Grid size={{ sm: 5, md: 4 }} sx={{ margin: '0 auto', textAlign: 'center' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                  sx={{
                    borderLeft: lineWeight,
                    borderColor: 'secondary.main',
                    height: '50px',
                    mt: 0,
                  }}
                />
              </Box>
              <MotionWrapper isSmallBreakpoint={isSmallBreakpoint}>
                <Typography sx={{ ...StepTitleStyle }}>{stepText[1].title}</Typography>
                <Typography sx={StepTextStyle}>{stepText[1].description}</Typography>
              </MotionWrapper>
            </Grid>
            <Grid size={{ sm: 1, md: 2 }} />
            <Grid size={{ sm: 5, md: 4 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                  sx={{
                    borderLeft: lineWeight,
                    borderColor: 'secondary.main',
                    height: '50px',
                    mt: 0,
                  }}
                />
              </Box>
              <MotionWrapper isSmallBreakpoint={isSmallBreakpoint}>
                <Typography sx={{ ...StepTitleStyle }}>{stepText[3].title}</Typography>
                <Typography sx={StepTextStyle}>{stepText[3].description}</Typography>
              </MotionWrapper>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default ProcessSection;

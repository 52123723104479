import { Helmet } from 'react-helmet-async';
import AppBarWithLogo from '../components/AppBarWithLogo';
import BottomNav from '../components/BottomNav';
import { Box, Typography, Button } from '@mui/material';
import PackagesSection, { PackageItem } from '../components/ServiceComponents/PackagesSection';
import BitBlossomDifference from '../components/ServiceComponents/BitBlossomDifference';
import ProcessSection, { stepText } from '../components/ServiceComponents/ProcessSection';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { useTheme, useMediaQuery } from '@mui/material';
import { JsonLd } from 'react-schemaorg';
import { Service } from 'schema-dts';
import { organizationData } from '../data/meta';
const h1Style = {
    fontSize: {
        xs: '24px',
        sm: '32px',
        md: '40px',
        lg: '40px' // Make lg the same as md
    },
    fontFamily: 'Signika',
    color: 'primary.main',
    lineHeight: {
        xs: 1,
        md: 1.5,
    },
};

const h2Style = {
    fontSize: {
        xs: '16px',
        sm: '24px',
        md: '30px',
    },
    fontFamily: 'Signika',
    lineHeight: {
        xs: 1,
        md: 1.5,
    },
};

const paragraphStyle = {
    fontFamily: 'DM Sans',
    fontSize: {
        xs: '12px',
        sm: '14px',
        md: '18px',
        lg: '18px',
    },
    lineHeight: 1.8,
};


const IncludedInPackage: PackageItem[] = [
    {
        title: 'Troubleshooting & Issue Resolution',
        description: 'Quick diagnosis and resolution of software, hardware, and network issues to minimize downtime.',
    },
    {
        title: 'System Updates & Maintenance',
        description: 'Regular updates and proactive maintenance to keep your systems optimized and secure.',
    },
    {
        title: 'Software Installations & Upgrades',
        description: 'Ensure new software or upgrades are installed properly and don’t disrupt your workflow.',
    },
    {
        title: "And More",
        description: "Looking for something else? Let us know!"
    }
];

const steps: stepText[] = [
    {
        title: "Step 1: Initial Consult",
        description: "We start with a detailed conversation to understand your challenges, current systems, and goals."
    },
    {
        title: "Step 2: Strategic Recommendations",
        description: "We deliver actionable recommendations and solutions based on your budget, growth plans, and desired outcomes."
    },
    {
        title: "Step 3: Implementation Support",
        description: "We’ll be by your side during the implementation phase to ensure everything runs smoothly."
    },
    {
        title: "Step 4:  Ongoing Guidance",
        description: "For clients seeking long-term support, we offer continuous IT consulting to adapt your technology as your business evolves."
    }
];

const differenceItems = [
    {
        icon: <DirectionsRunIcon />,
        title: 'Fast, Reliable Support',
        paragraph:
            'We prioritize minimizing your downtime with fast, effective solutions so you can keep your business moving forward.',
    },
    {
        icon: <EmojiObjectsOutlinedIcon />,
        title: 'Support Tailored to Your Business',
        paragraph:
            'No cookie-cutter fixes here. We understand your unique needs and customize our support to match them.',
    },
    {
        icon: <FavoriteBorderOutlinedIcon />,
        title: 'We’re in it with you',
        paragraph:
            'We know tech issues can be stressful. That’s why we approach every problem with patience, clear communication, and empathy.',
    },
    {
        icon: <CheckBoxOutlinedIcon />,
        title: 'Tailored, Actionable Advice',
        paragraph:
            'We provide recommendations that make sense for your business, focusing on cost-effective solutions and measurable outcomes.',
    },
];



const ITTechSupportPage: React.FC = () => {
    const theme = useTheme();
    const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            <Helmet>
                <title>IT Consulting + Tech Support | Bit Blossom Software</title>
                <meta name="description" content="Strategic tech guidance for sustainable growth. Bit Blossom Software offers expert IT (Information Technology) consulting and tech support to help optimize your technology strategy." />
                <link rel="canonical" href="https://www.bitblossomsoftware.com/services/it-tech-support" />
            </Helmet>
            {/* JSON‑LD for IT Consulting + Tech Support */}
            <JsonLd<Service>
                item={{
                    "@context": "https://schema.org",
                    "@type": "Service",
                    name: "IT Consulting + Tech Support",
                    description: "Strategic tech guidance for sustainable growth. Bit Blossom Software provides expert IT consulting and" +
                                 " tech support to optimize your technology strategy.  Whether you're launching a new project, upgrading"+
                                 " systems, or navigating digital transformation, we're here to help.  We specialize in troubleshooting"+
                                 " and issue resolution, system updates and maintenance, software installations and upgrades, and more.",
                    serviceType: "IT Consulting and Tech Support",
                    potentialAction: {
                        "@type": "InteractAction",
                        target: "https://www.bitblossomsoftware.com/contact",
                        name: "Contact Us"
                    },
                    provider: organizationData,
                    areaServed: "US",
                    availableChannel: {
                        "@type": "ServiceChannel",
                        serviceUrl: "https://www.bitblossomsoftware.com/services/it-tech-support",
                    }
                }}
            />
            <AppBarWithLogo
                logoAlt="Bit Blossom Software Logo"
                logoSrc={`/BitBlossom_Logo_Hztl_cropped.png`}
                appName="Bit Blossom Software"
                useDark={false}
            />
            <Box sx={{ overflowX: 'hidden' }}>
                <Box style={{
                    position: 'relative', overflow: 'visible', width: '100%'
                }}>
                    <Box
                        component="img"
                        src="/LaptopTypingCode.jpg"
                        alt="Contact Us Top Banner"
                        sx={{
                            display: 'block',
                            width: '100%',
                            height: 'auto',
                            objectFit: 'cover',
                            opacity: 0.15,
                            zIndex: 0,
                        }}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: { xs: '100%', md: '75%' },
                            height: '100%',
                            flexDirection: 'column',
                            mt: { xs: 0, md: 10 },
                            ml: { xs: 2, md: 10 },
                            pt: { xs: 2, md: 0 },
                            color: 'white',
                            minHeight: '500px',
                        }}
                    >
                        <Typography
                            component="p"
                            variant="h1"
                            sx={{
                                textAlign: 'left',
                                mb: 1,
                                ...h1Style
                            }}
                        >
                            IT Consulting + Tech Support
                        </Typography>

                        <Typography
                            component="p"
                            variant="h2"
                            sx={{
                                textAlign: 'left',
                                ...paragraphStyle,
                                color: 'primary.main',
                                opacity: 0.7,
                            }}
                        >
                            Strategic Tech Guidance for Sustainable Growth
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', mt: {xs:'-45%', md:'-35%'}, pb:{xs: 2, md:5}, pt:{xs: 1, md:2}, mb:{xs:1, md:0}, backgroundColor: 'white', opacity: 0.9 }}>
                    <Typography variant="h1" sx={{ ...h1Style }}>
                        Why IT Consulting + Tech Support Matters
                    </Typography>
                    <Typography variant="h1" sx={{ ...paragraphStyle, mt: 2.5, color: 'secondary.main', opacity: 0.80, mb: { sm: 0, md: 3 }, ml:{xs: 2, md:10}, mr:{xs: 2, md:10} }}>
                        The right technology decisions can make or break a business. Whether you're launching a new project, upgrading systems, or navigating digital transformation, Bit Blossom Software is here to help.
                    </Typography>
                </Box>
                <PackagesSection h2Style={h2Style}
                    imageSrc={"/ITConsulting.jpg"}
                    packageItems={IncludedInPackage}
                    title={"What We Can Help You With:"}
                    secondaryTitle=''
                    imageWidth={{ xs: '20%', md: '30%', lg: '30%' }}
                    backgroundColor='transparent'
                    margin={isSmallBreakpoint ? '1' : '0%'}
                    customMargin={true}
                    />
                <ProcessSection h1Style={h1Style} stepText={steps} />
                <BitBlossomDifference header="The Bit Blossom Difference"
                    items={differenceItems}
                    imageSrc="/ContactUsOwnerPic.jpg"
                    longerText={true}
                />
                <Box sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', mt: 5, mb: 5,ml:{xs:1}, mr:{xs:1} }}>
                    <Typography variant="h1" sx={{ ...h1Style }}>
                        Ready to Optimize Your Tech Strategy?
                    </Typography>
                    <Typography variant="h1" sx={{ ...paragraphStyle, mt: 2.5, color: 'secondary.main', opacity: 0.80, mb: { sm: 0, md: 3 },
                    ml:{xs:2}, mr:{xs:2}
                }}>
                        Let’s simplify your technology, solve challenges, and build a roadmap for long-term success.
                    </Typography>
                    <Button variant="contained" sx={{ mt: 2.5, color: 'white', backgroundColor: 'primary.main' }} href="/contact">
                        Get Expert Guidance
                    </Button>
                </Box>
                <BottomNav useDark={true} />
            </Box>
        </>
    );
};

export default ITTechSupportPage;
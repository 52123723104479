import React from 'react';
import { Box, Typography, Button } from '@mui/material';


export interface PackageItem {
  title: string;
  description: string;
};

interface PackageSectionProps {
  h2Style: any;
  packageItems: PackageItem[];
  imageSrc: string;
  title: string;
  secondaryTitle: string;
  imageWidth: any;
  backgroundColor?: string;
  margin?: string;
  customMargin?: boolean;
}

const paragraphStyle = {
  fontFamily: 'DM Sans',
  fontSize: {
      xs: '14px',
      sm: '18px',
      md: '20px',
  },
  lineHeight: 1.8,
};

const BULLET_ITEM = '>>';

const PackagesSection: React.FC<PackageSectionProps> = ({ h2Style, packageItems,imageSrc,title,secondaryTitle,imageWidth,backgroundColor,margin,customMargin }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'row', md: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        mt: {xs: margin || '-40%', md: margin || '-40%'},
        mb: {xs:customMargin ? 0 : 12,s:4,md:5, lg:15},
        position: 'relative'   }} >
              <Box
          sx={{
            display: 'block',
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
            opacity: 0.15,
            zIndex: 1,
            backgroundColor: backgroundColor || 'secondary.main',
            minHeight: { xs: '730px',sm:'900px', md: '1100px', lg: '1000px' }, // Make lg the same as md
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      <Box
        sx={{
          width: { xs: '80%', md: '50%', lg: '50%' }, // Make lg the same as md
          height: { xs:customMargin?'600px':'690px', sm: '850px', md: '975px', lg: '800px' }, // Make lg the same as md
          bgcolor: 'white',
          zIndex: 2,
          paddingRight: { xs:2, md: 3, lg: 3 }, // Make lg the same as md
          paddingLeft: { xs:1, md: 3, lg: 3 }, // Make lg the same as md
          mt: { xs: 1, md: 10, lg: 10 }, // Make lg the same as md
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography sx={{ ...h2Style, color: 'primary.main', textAlign: 'left', mt: 2 }}>
          {title}
        </Typography>
        <Typography sx={{ ...h2Style, lineHeight: { sm: 1.2, md: 1.0, lg: 1.0 }, color: 'secondary.main', textAlign: 'left', mt: 0 }}>
          {secondaryTitle}
        </Typography>
        {packageItems.map((item, index) => (
          <React.Fragment key={`fragment_${index}`}>
            <Typography key={`title_${index}`} variant="subtitle1" sx={{ mt: 3, ...paragraphStyle, fontWeight: 'Medium', pb: 0, color: 'primary.main' }}>
              {BULLET_ITEM} {item.title}
            </Typography>
            <Typography key={`sentence_${index}`} variant="subtitle1" sx={{
              ...paragraphStyle,
              fontFamily: 'DM Sans',
              fontWeight: 'Medium',
              ml: { md: 1, lg: 1 }, // Make lg the same as md
              mr: { md: 2, lg: 2 }, // Make lg the same as md
              color: 'secondary.main'
            }}>
              {item.description}
            </Typography></React.Fragment>
        ))}

        <Box sx={{ mt: { xs: 1, md: 4, lg: 4 }, display: 'flex', justifyContent: 'center' }}>
            <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ ...paragraphStyle ,padding: { xs: '2px 4px', md: '4px 8px' } }}
            href="/contact"
            >
            Start Your Project
            </Button>
        </Box>
      </Box>
      <Box
        component="img"
        src={imageSrc}
        alt="Example"
        sx={{
          width: imageWidth,
          height: 'auto',
          mt: { xs: 0, md: 10 },
          ml: { xs: -1, md: -2 },
          zIndex: 3,
          display: { xs: 'none', md: 'block' },
        }} />
    </Box>

  );
}


export default PackagesSection;
import { Helmet } from 'react-helmet-async';
import AppBarWithLogo from '../components/AppBarWithLogo';
import BottomNav from '../components/BottomNav';
import { Box, Typography, Button } from '@mui/material';
import PackagesSection, { PackageItem } from '../components/ServiceComponents/PackagesSection';
import BitBlossomDifference from '../components/ServiceComponents/BitBlossomDifference';
import ProcessSection, { stepText } from '../components/ServiceComponents/ProcessSection';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import { JsonLd } from 'react-schemaorg';
import { Service } from 'schema-dts';
import { organizationData } from "../data/meta";


const h1Style = {
    fontSize: {
        xs: '24px',
        sm: '32px',
        md: '40px',
        lg: '40px' // Make lg the same as md
    },
    fontFamily: 'Signika',
    color: 'primary.main',
    lineHeight: {
        xs: 1,
        md: 1.5,
    },
};

const h2Style = {
    fontSize: {
        xs: '16px',
        sm: '24px',
        md: '30px',
    },
    fontFamily: 'Signika',
    lineHeight: {
        xs: 1,
        md: 1.5,
    },
};

const paragraphStyle = {
    fontFamily: 'DM Sans',
    fontSize: {
        xs: '12px',
        sm: '14px',
        md: '18px',
        lg: '18px',
    },
    lineHeight: 1.8,
};


const IncludedInPackage: PackageItem[] = [
    {
        title: 'Custom-Built Features',
        description: 'Whether it’s automation, analytics, or internal tools, we design features that support your business needs.',
    },
    {
        title: 'Scalable Architecture',
        description: 'Built to grow with your business, ensuring long-term flexibility and performance.',
    },
    {
        title: 'Third-Party Integrations',
        description: 'Seamlessly connect to CRMs, accounting software, marketing platforms, or any essential tools.',
    },
    {
        title: "Ongoing Maintenance Options",
        description: "After launch, opt for continuous updates, optimizations, and technical support to keep everything running smoothly."
    }
];

const steps: stepText[] = [
    {
        title: "Step 1: Onboarding",
        description: "We start by learning everything we can about your business, brand, and goals. This allows us to create a strategy tailored to your needs."
    },
    {
        title: "Step 2: Collaboration & Design",
        description: "We create wireframes and mockups to give you a clear preview of your site’s structure, with your feedback guiding the process."
    },
    {
        title: "Step 3: Development",
        description: "Once you’re happy with the design, we bring it to life. We use modern coding practices to ensure a secure, scalable, and high-performing site."
    },
    {
        title: "Step 4: Launch & Support",
        description: "Celebrate! Your site goes live, and we provide you with the training you need to manage updates easily."
    }
];

const differenceItems = [
    {
        icon: <TrendingUpIcon />,
        title: 'Built for Your Growth',
        paragraph:
            'Your software is built with the future in mind, ready to evolve as your business grows and changes.',
    },
    {
        icon: <EmojiObjectsOutlinedIcon />,
        title: 'Strategy Meets Creativity',
        paragraph:
            'It’s not just about making things look good. Every design decision is guided by your brand’s goals to ensure your site is strategic, targeted, and impactful.',
    },
    {
        icon: <FavoriteBorderOutlinedIcon />,
        title: 'We’re in it with you',
        paragraph:
            ' Our goal is to be your long-term partner, helping you navigate changes, updates, and growth opportunities.',
    },
    {
        icon: <CheckBoxOutlinedIcon />,
        title: 'Seamless & Stress Free',
        paragraph:
            'We know you’re busy, so we’ve streamlined our process to be smooth and simple—minimizing stress and maximizing results.',
    },
];



const CustomSoftwareServicesPage: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Custom Software | Bit Blossom Software</title>
                <meta name="description" content="" />
                <link rel="canonical" href="https://www.bitblossomsoftware.com/services/custom-software" />
            </Helmet>
            <JsonLd<Service>
                item={{
                    "@context": "https://schema.org",
                    "@type": "Service",
                    name: "Custom Software Development",
                    serviceType: "Custom Software",
                    provider: organizationData,
                    description:
                        "Tailored functionality, endless possibilities. We design and build custom software solutions for your unique business needs." +
                        "  Whether you need a mobile app, a web application, cloud-based software, or something else entirely, we can help." +
                        "  We support the iOs, Android, web platforms, Microsoft Windows and Mac OS, and we can integrate with your existing systems or build something entirely new." +
                        "  Our custom software solutions are designed to grow with your business, ensuring long-term flexibility and performance.",
                    areaServed: "US",
                    potentialAction: {
                            "@type": "InteractAction",
                            target: "https://www.bitblossomsoftware.com/contact",
                            name: "Contact Us"
                    },
                    hasOfferCatalog: {
                        "@type": "OfferCatalog",
                        name: "What's Included",
                        itemListElement: [
                            {
                                "@type": "Offer",
                                itemOffered: {
                                    "@type": "Service",
                                    name: "Custom-Built Features",
                                    description:
                                        "Whether it’s automation, analytics, or internal tools, we design features that support your business needs.",
                                },
                            },
                            {
                                "@type": "Offer",
                                itemOffered: {
                                    "@type": "Service",
                                    name: "Scalable Architecture",
                                    description:
                                        "Built to grow with your business, ensuring long-term flexibility and performance.",
                                },
                            },
                            {
                                "@type": "Offer",
                                itemOffered: {
                                    "@type": "Service",
                                    name: "Third-Party Integrations",
                                    description:
                                        "Seamlessly connect to CRMs, accounting software, marketing platforms, or other essential tools.",
                                },
                            },
                            {
                                "@type": "Offer",
                                itemOffered: {
                                    "@type": "Service",
                                    name: "Ongoing Maintenance Options",
                                    description:
                                        "After launch, opt for continuous updates, optimizations, and technical support to keep everything running smoothly.",
                                },
                            },
                        ],
                    },

                }}
            />
            <AppBarWithLogo
                logoAlt="Bit Blossom Software Logo"
                logoSrc={`/BitBlossom_Logo_Hztl_cropped.png`}
                appName="Bit Blossom Software"
                useDark={false}
            />
            <Box sx={{ overflowX: 'hidden' }}>
                <Box style={{
                    position: 'relative', overflow: 'visible', width: '100%'
                }}>
                    <Box
                        component="img"
                        src="/LaptopTypingCode.jpg"
                        alt="Contact Us Top Banner"
                        sx={{
                            display: 'block',
                            width: '100%',
                            height: 'auto',
                            objectFit: 'cover',
                            opacity: 0.15,
                            zIndex: 0,
                        }}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: { xs: '100%', md: '75%' },
                            height: '100%',
                            flexDirection: 'column',
                            mt: { xs: 0, md: 10 },
                            ml: { xs: 2, md: 10 },
                            pt: { xs: 2, md: 0 },
                            color: 'white',
                            minHeight: '500px',
                        }}
                    >
                        <Typography
                            component="p"
                            variant="h1"
                            sx={{
                                textAlign: 'left',
                                mb: 1,
                                ...h1Style
                            }}
                        >
                            Custom Software
                        </Typography>

                        <Typography
                            component="p"
                            variant="h2"
                            sx={{
                                textAlign: 'left',
                                ...paragraphStyle,
                                color: 'primary.main',
                                opacity: 0.7,
                            }}
                        >
                            Tailored functionality, endless possibilities
                        </Typography>
                    </Box>
                </Box>
                <PackagesSection h2Style={h2Style}
                    imageSrc={"/CustomSoftware.jpg"}
                    packageItems={IncludedInPackage}
                    title={"Custom Software:"}
                    secondaryTitle='Tailored to Your Unique Needs'
                    imageWidth={{ xs: '20%', md: '30%', lg: '30%' }} />

                <ProcessSection h1Style={h1Style} stepText={steps} />
                <BitBlossomDifference header="The Bit Blossom Difference"
                    items={differenceItems}
                    imageSrc="/ContactUsOwnerPic.jpg"
                    longerText={true}
                />
                <Box sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', mt: 5, mb: 5 }}>
                    <Typography variant="h1" sx={{ ...h1Style }}>
                        Excited to  Build Something Custom?
                    </Typography>
                    <Typography variant="h1" sx={{ ...paragraphStyle, mt: 2.5, color: 'secondary.main', opacity: 0.80, mb: { sm: 0, md: 3 } }}>
                        Let’s create software that works as hard as you do!
                    </Typography>
                    <Button variant="contained" sx={{ mt: 2.5, color: 'white', backgroundColor: 'primary.main' }} href="/contact">
                        Start Your Project
                    </Button>
                </Box>
                <BottomNav useDark={true} />
            </Box>
        </>
    );
};

export default CustomSoftwareServicesPage;
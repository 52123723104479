import { Box, Typography, useMediaQuery } from '@mui/material';
interface BehindTechSectionProps {
    h1Style: any;
    paragraphStyle: any;
}

const BehindTechSection: React.FC<BehindTechSectionProps> = ({ h1Style, paragraphStyle }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <Box
            sx={{
                position: 'relative',
                overflow: 'visible',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                mt: isMobile ? 5 : 10,
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    overflow: 'visible',
                    width: '60%', // Adjusted to take up 60% of the page
                    height: { xs:'200px',md:'800px'},
                    display: { xs: 'none', sm: 'block' }
                }}
            >
                {/* Image 1: Top left */}
                <Box
                    component="img"
                    src="BehindTheTech1.jpg"
                    alt="Tech Image 1"
                    sx={{
                        position: 'absolute',
                        top: 20,
                        left: 0,
                        width: '40%', // Adjust width as needed
                        objectFit: 'cover',
                        zIndex: 2, // Ensure it overlaps Image 2
                        ml: 4,
                    }}
                />
                {/* Image 2: Center right */}
                <Box
                    component="img"
                    src="BehindTheTech2.jpg"
                    alt="Tech Image 2"
                    sx={{
                        position: 'absolute',
                        top: '60%',
                        right: '-15%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%', 
                        objectFit: 'cover',
                        zIndex: 1, 
                    }}
                />
                {/* Image 3: Bottom left */}
                <Box
                    component="img"
                    src="BehindTheTech3.jpg"
                    alt="Tech Image 3"
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '40%', // Adjust width as needed
                        objectFit: 'cover',
                        zIndex: 2, // Ensure it overlaps Image 2
                        ml: 8,
                    }}
                />
            </Box>
            <Box sx={{ width: {xs:'70%', md:'40%'}, mr: {xs:0, md:5}, ml: {xs:5, md:0}}}>
                <Typography
                    variant="h1"
                    sx={{ ...h1Style, textAlign: 'center', lineHeight: { xs: 1, md: 0.5} }}
                >
                    Behind The Tech:
                </Typography>
                <Typography
                    variant="h1"
                    sx={{
                        ...h1Style,
                        
                        textAlign: 'center',
                        color: 'secondary.main',
                    }}
                >
                    Get To Know Me
                </Typography>
                <Typography variant="body1" sx={{ ...paragraphStyle, mt: 3, textAlign: 'left', color: 'primary.main' }}>
                    &gt;&gt; Audiobook Addict
                </Typography>
                <Typography variant="body1" sx={{ ...paragraphStyle, mt: 1, ml: 3, textAlign: 'left', color: 'secondary.main' }}>
                    I&rsquo;m always listening to something—whether it&rsquo;s a gripping thriller or a deep dive into business strategies. Got a favorite? Send it my way!
                </Typography>
                <Typography variant="body1" sx={{ ...paragraphStyle, mt: 3, textAlign: 'left', color: 'primary.main' }}>
                    &gt;&gt; Biking Enthusiast
                </Typography>
                <Typography variant="body1" sx={{ ...paragraphStyle, mt: 1, ml: 3, textAlign: 'left', color: 'secondary.main' }}>
                    In The summer you can catch me biking with my Husband, Abram anywhere from 15-100 miles a day. Its our favorite way to get outside and enjoy the summer.
                </Typography>
                <Typography variant="body1" sx={{ ...paragraphStyle, mt: 3, textAlign: 'left', color: 'primary.main' }}>
                    &gt;&gt; Road Tripper Extraordinaire
                </Typography>
                <Typography variant="body1" sx={{ ...paragraphStyle, mt: 1, ml: 3, textAlign: 'left', color: 'secondary.main' }}>
                    My favorite adventure? A road trip to Glacier National Park for camping and hiking. The views were absolutely unforgettable.
                </Typography>
                <Typography variant="body1" sx={{ ...paragraphStyle, mt: 3, textAlign: 'left', color: 'primary.main' }}>
                    &gt;&gt; Organization Obsessed
                </Typography>
                <Typography variant="body1" sx={{ ...paragraphStyle, mt: 1, ml: 3, textAlign: 'left', color: 'secondary.main' }}>
                    Give me a label maker and some storage bins, and I&rsquo;m in heaven. Let&rsquo;s just say, systems are my love language.
                </Typography>
            </Box>
        </Box>
    );
}


export default BehindTechSection;
import { Box, Typography, Button, useMediaQuery } from '@mui/material';

interface AboutSydneyProps { }

const h1Style = {
  fontSize: {
    xs: '24px',
    sm: '32px',
    md: '40px',
  },
  fontFamily: 'Signika',
  color: 'primary.main',
};

const GetDesktopView = ({ isMobile }: { isMobile: boolean }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'visible',
        width: '100%',
        mt: isMobile ? 35 : 10
      }}
    >
      {/* Background Image */}
      <Box
        component="img"
        src="AboutSydneyBackground.jpg"
        alt="Home Page Top Banner"
        sx={{
          display: 'block',
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
          opacity: '60%',
        }}
      />

      {/* Overlay Container (positioned above background) */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* Left Column: Image on top of the background */}
        <Box
          component="img"
          src="AboutSydney.jpg"
          alt="Sydney Founder"
          sx={{
            height: '60%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: -8,
            zIndex: 0
          }}
        >
        </Box>

        {/* Right Column: White box overlay */}
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            bgcolor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 10,
            pb: 10,
          }}
        >
          {/* Container for your 4 rows */}
          <Box
            sx={{
              ml: 12,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              mr: 4,
            }}
          >
            {/* Row 1: Title */}
            <Typography variant="h1" color="primary.main" sx={{ ...h1Style }}>
              I’m Sydney
            </Typography>

            {/* Row 2: Subtitle */}
            <Typography variant="subtitle1" color="secondary.main" sx={{ fontFamily: 'DM Sans', fontSize: 16, fontWeight: 'Medium', pb: 2 }}>
              A Tech Girly based in Fargo, North Dakota
            </Typography>

            {/* Row 3: Paragraph */}
            <Typography variant="body1" color="primary.main" sx={{
              fontFamily: 'DM Sans', fontSize: {
                sm: '8px',
                md: '16px',
              },
            }}>
              After years of helping businesses navigate their tech needs, I turned my passion for problem-solving into a business built on connection and trust. Whether it’s crafting stunning websites, building custom software, or offering IT guidance, I’m  dedicated to making technology accessible and empowering.
              <br /><br />
              I believe in doing work that matters and living a life we love—one filled with meaningful projects, time with family and friends, and a commitment to supporting  businesses.
              <br /><br />
              I’m here to help you simplify technology so you can focus on growing your business and chasing your dreams.
            </Typography>

            {/* Row 4: Footer or small note */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between', // Text on left, button on right
                mt: 5,
              }}
            >
              <Typography
                color="secondary.main"
                sx={{
                  fontFamily: 'Satisfy',
                  fontSize: { xs: 12, md: 40 }
                }}
              >
                Sydney
              </Typography>
              {!isMobile && (
                <Button
                  variant="outlined"
                  sx={{
                    // text and border use 'primary.main'
                    color: 'primary.main',
                    borderColor: 'primary.main',
                    // optionally adjust hover styles:
                    '&:hover': {
                      borderColor: 'primary.dark',
                      backgroundColor: '#fff', // or slightly tinted if you prefer
                    },
                  }}
                  onClick={() => window.location.href = '/about'}
                >
                  GET TO KNOW ME {'>'}
                </Button>)}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};


const AboutSydneySection: React.FC<AboutSydneyProps> = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  if (!isMobile) {
    return <GetDesktopView isMobile={isMobile} />;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        mt: 10,
        maxWidth: '100%',
        p: 1,
        // Create a pseudo-element with the background image and desired opacity
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: 'url(AboutSydneyBackground.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.6, // 60% opacity
          zIndex: -1, // Ensure it's behind the content
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'white',
          p: 4,
          mx: 'auto',
          width: { xs: '90%', md: '50%' },
        }}
      >
        <Box
          component="img"
          src="AboutSydney.jpg"
          alt="Sydney Founder"
          sx={{
            width: { xs: '100%', md: '50%' },
            mr: 2,
          }}
        />
        <Box>
          <Typography variant="h1" color="primary.main" sx={{ ...h1Style }}>
            I’m Sydney
          </Typography>
          <Typography variant="subtitle1" color="secondary.main" sx={{ fontFamily: 'DM Sans', fontSize: 16, fontWeight: 'Medium', pb: 2 }}>
            A Tech Girly based in Fargo, North Dakota
          </Typography>
          <Typography variant="body1" color="primary.main" sx={{
            fontFamily: 'DM Sans', fontSize: {
              sm: '8px',
              md: '16px',
            },
          }}>
            After years of helping businesses navigate their tech needs, I turned my passion for problem-solving into a business built on connection and trust. Whether it’s crafting stunning websites, building custom software, or offering IT guidance, I’m  dedicated to making technology accessible and empowering.
            <br /><br />
            I believe in doing work that matters and living a life we love—one filled with meaningful projects, time with family and friends, and a commitment to supporting  businesses.
            <br /><br />
            I’m here to help you simplify technology so you can focus on growing your business and chasing your dreams.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: 5,
            }}
          >
            <Typography
              color="secondary.main"
              sx={{
                fontFamily: 'Satisfy',
                fontSize: { xs: 25, md: 40 }
              }}
            >
              Sydney
            </Typography>
            {!isMobile && (
              <Button
                variant="outlined"
                sx={{
                  color: 'primary.main',
                  borderColor: 'primary.main',
                  '&:hover': {
                    borderColor: 'primary.dark',
                    backgroundColor: '#fff',
                  },
                }}
                onClick={() => (window.location.href = '/about')}
              >
                GET TO KNOW ME {'>'}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>

  );
};

export default AboutSydneySection;

import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import AppBarWithLogo from '../components/AppBarWithLogo';
import BottomNav from '../components/BottomNav';
import { Box, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Grid2'
import AboutSydneySection from '../components/HomeComponents/AboutSydneySection';
import QuotesSection from '../components/HomeComponents/QuotesSection';
import ContactUsSection from '../components/HomeComponents/ContactUsSection';
import { Service } from "schema-dts";
import { JsonLd } from "react-schemaorg";
import { GetOrganizationMeta } from '../data/meta'
import { motion } from 'framer-motion';

const h1Style = {
    fontSize: {
        xs: '24px',
        sm: '32px',
        md: '40px',
    },
    fontFamily: 'Signika',
    color: 'primary.main',
    lineHeight: {
        xs: 1,
        md: 1.5,
    },
};

const paragraphStyle = {
    fontFamily: 'DM Sans',
    fontSize: {
        xs: '14px',
        sm: '18px',
        md: '20px',
    },
    lineHeight: 1.8,
};

// Example data for the four services
const servicesData = [
    {
        image: 'WebDesignDev.jpg',
        title: 'Web Design + Development',
        navLink: '/services/web-development',
        description:
            'Crafting websites that are as functional as they are beautiful. We create custom, user-friendly designs that reflect your brand and help your business grow online.',
    },
    {
        image: 'CustomSoftware.jpg',
        title: 'Custom Software',
        navLink: '/services/custom-software',
        description:
            'Solutions built just for you. Whether you need streamlined operations, advanced tools, or automation, our software is tailored to your business’s unique needs.',
    },
    {
        image: 'ITConsulting.jpg',
        title: 'IT Consulting',
        navLink: '/services/it-tech-support',
        description:
            'Expert advice to help you navigate complex tech decisions. We provide strategic guidance to ensure your technology supports your goals and drives your success.',
    },
    {
        image: 'TechSupport.jpg',
        title: 'Tech Support',
        navLink: '/services/it-tech-support',
        description:
            'Reliable tech support to keep your business running smoothly. From troubleshooting to proactive maintenance, we’ve got your back every step of the way.',
    },
];

const Home: React.FC = () => {
    const contactUsRef = useRef<HTMLDivElement>(null);

    const scrollToContactUs = () => {
        contactUsRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <>
            <Helmet>
                <title>Bit Blossom Software</title>
                <meta name="description" content="Bit Blossom Software provides heart-centered professional services around software solutions in Fargo, ND. Empowering businesses with compassionate, tailored technology solutions." />
                <link rel="canonical" href="https://www.bitblossomsoftware.com" />
            </Helmet>
            {GetOrganizationMeta()}
            {/* Service structured data for each service */}
            {servicesData.map((service, index) => (
                <JsonLd<Service>
                    key={index}
                    item={{
                        "@context": "https://schema.org",
                        "@type": "Service",
                        name: service.title,
                        provider: {
                            "@type": "Organization",
                            name: "Bit Blossom Software",
                            url: "https://www.bitblossomsoftware.com"
                        },
                        description: service.description
                    }}
                />
            ))}
            <AppBarWithLogo
                logoAlt="Bit Blossom Software Logo"
                logoSrc={`BitBlossom_Logo_Hztl_cropped.png`}
                appName="Bit Blossom Software"
                useDark={false}
            />
            <Box sx={{ overflowX: 'hidden' }}>
                <Box>
                    {/* Empowering Businesses Section */}
                    <Box style={{ position: 'relative', overflow: 'visible', width: '100%', }}>
                        <Box
                            component="img"
                            src="HomePageTopBanner.jpg"
                            alt="Home Page Top Banner"
                            sx={{
                                display: 'block',
                                width: '100%',
                                height: 'auto',
                                objectFit: 'cover',
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                display: 'flex',
                                top: 0,
                                left: 0,
                                width: '50%',
                                height: '100%',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                ml: 4,
                                pt: { xs: 2, md: 0 },
                                color: 'white',
                            }}
                        >
                            <Typography
                                component="p"
                                variant="h1"
                                sx={{
                                    textAlign: 'left',
                                    mb: 1,
                                    ...h1Style
                                }}
                            >
                                Empowering businesses with tailored tech solutions.
                            </Typography>

                            <Typography
                                component="p"
                                variant="h2"
                                sx={{
                                    textAlign: 'left',
                                    ...paragraphStyle,
                                    color: 'white',
                                }}
                            >
                                Web Development | Custom Software | IT Support
                            </Typography>
                            {/* Button to scroll to Contact Us Section */}
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    ...paragraphStyle,
                                    mt: 3,
                                    width: '200px', // Ensures the button width fits the text
                                    px: 3, // Adds horizontal padding for better spacing
                                    textTransform: 'none', // Keeps the text casing as is
                                    display: { xs: 'none', md: 'inline-block' }, // Adjusts the button display based on screen size
                                }}
                                onClick={scrollToContactUs}
                            >
                                Contact Us Now
                            </Button>
                        </Box>
                        <Box
                            component="img"
                            src="BitBlossom_Logo_Badge.png"
                            alt="Bit Blossom Badge"
                            sx={{
                                position: 'absolute',
                                // anchor to bottom-right corner
                                bottom: 0,
                                right: 0,
                                // shift it downward so half is outside
                                transform: 'translateY(50%)',
                                // adjust sizing
                                width: { xs: 50, md: 200 },
                                height: 'auto',
                                // optional spacing from the right
                                mr: { xs: 2, md: 4 },
                                '@keyframes spin': {
                                    '0%': {
                                        transform: 'translateY(50%) rotate(0deg)',
                                    },
                                    '100%': {
                                        transform: 'translateY(50%) rotate(360deg)',
                                    },
                                },

                                // 2) Attach the animation: <name> <duration> <timing-function> <infinite or count>
                                animation: 'spin 25s linear infinite',

                            }}
                        />
                    </Box>

                    {/* Struggling with Tech Overwhelm Section */}
                    <Box
                        component="section"
                        sx={{
                            mt: 5,
                            backgroundColor: 'background.default',
                            textAlign: 'center',
                            flexDirection: 'column',
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: "center",
                            ml: { xs: 1, md: 0 },
                            marginBottom: { xs: 1, md: 0 },
                        }}
                    >
                        <Typography
                            variant="h1"
                            sx={{
                                ...h1Style
                            }}
                        >
                            Struggling with Tech Overwhelm?
                        </Typography>

                        <Typography
                            variant="h2"
                            sx={{
                                marginTop: 5,
                                color: 'primary.main',
                                maxWidth: '800px',
                                textAlign: 'center',
                                opacity: '70%',
                                ...paragraphStyle,
                            }}
                        >
                            We get it—managing IT can be stressful and time-consuming. That’s why we’re here to simplify
                            your technology, tackle your toughest challenges, and give you back the time to focus on what
                            you do best.
                        </Typography>
                    </Box>

                    {/* Services Section */}
                    <Box
                        component="section"
                        sx={{
                            mt: 10,
                            backgroundColor: 'background.default',
                            textAlign: 'center',
                            flexDirection: 'column',
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: "center",
                            maxBlockSize: '100%',
                            maxHeight: '100%',
                        }}
                    >
                        <Typography
                            variant="h1"
                            component={'h2'}
                            sx={{
                                ...h1Style,
                                mb: 4
                            }}
                        >
                            Our Services
                        </Typography>
                        <Grid
                            container
                            spacing={4} // spacing between grid items
                            sx={{
                                width: '100%',
                                maxWidth: '1206px', // adjust if you want to limit the grid width
                                // margin: '0 auto', // center the grid container
                                px: { xs: 2, md: 4 },
                            }}
                        >
                            {servicesData.map((service, index) => (
                                <Grid
                                    size={{ md: 3, xs: 12 }}
                                    key={index}
                                >
                                    {/* Card-like Box */}
                                    <motion.div
                                        whileHover={{ scale: 1.05 }}
                                        transition={{ duration: 0.2 }}
                                        style={{ display: 'inline-block', originX: 0.5, originY: 0.5 }}
                                        onClick={() => window.location.href = service.navLink}
                                    >
                                        <Box
                                            sx={{
                                                overflow: 'hidden',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                p: 0,
                                                height: '100%',
                                                cursor: 'pointer', // Makes it look clickable
                                            }}
                                        >
                                            {/* Service Image */}
                                            <Box
                                                component="img"
                                                src={service.image}
                                                alt={service.title}
                                                sx={{
                                                    width: { md: '100%', xs: '75%' },
                                                    height: 'auto',
                                                    mb: 2,
                                                    borderRadius: 0,
                                                }}
                                            />

                                            {/* Service Title */}
                                            <Typography
                                                component="h1"
                                                variant="h5"
                                                sx={{
                                                    fontFamily: 'DM Sans',
                                                    color: 'primary.main',
                                                    fontSize: 20,
                                                    mb: 1,
                                                    fontWeight: 'Bold'
                                                }}
                                            >
                                                {service.title}
                                            </Typography>

                                            {/* Service Description */}
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontFamily: 'DM Sans',
                                                    color: 'primary.main',
                                                    opacity: '80%',
                                                    fontSize: 16
                                                }}
                                            >
                                                {service.description}
                                            </Typography>
                                        </Box>
                                    </motion.div>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <AboutSydneySection />
                    <QuotesSection />
                    <ContactUsSection ref={contactUsRef} />

                </Box>
            </Box>
            <BottomNav useDark={true} />
        </>
    );
};

export default Home;
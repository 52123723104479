export type Blog = {
    title: string;
    abstract: string;
    image: string;
    id: number;
    url: string;
    alt: string;
    date: string;
};

export const Blogs: Blog[] = [
    {
        title:"Speak Your Audience’s Language: How to Make Tech Content Click",
        abstract :"Tech should simplify, not confuse. Jargon-filled language frustrates users and drives them away — not because your product fails, but because it’s hard to understand. Clear, user-friendly language builds trust, reduces support needs, and makes tech feel accessible. By listening to users and reflecting their words, you create content that connects. Prioritize plain language, intuitive structure, and clarity over cleverness. It’s not just good UX — it’s good business.",
        image: "tech-content-language.webp",
        id: 6,
        url: "https://medium.com/@bitblossomsoftware/speak-your-audiences-language-how-to-make-tech-content-click-294f98a0ea7a",
        alt: "Speak Your Audience’s Language: How to Make Tech Content Click",
        date: '03/28/2025'
    },
    {
        title:"How to Train ChatGPT to Understand Your Business and Create Tailored Content",
        abstract: "Discover how to leverage custom ChatGPTs to align with your brand voice and values in our step-by-step guide.",
        image: "train-chatgpt-business.png",
        id: 5,
        url: "http://medium.com/@bitblossomsoftware/how-to-train-chatgpt-to-understand-your-business-and-create-tailored-content-9f779eab73a3",
        alt: "How to Train ChatGPT to Understand Your Business and Create Tailored Content",
        date: '03/26/2025'
    },
    {
        title: "Unlocking AI for Small Business Success: Tools You Can Use Today",
        abstract: "Discover how accessible AI tools can transform your small business operations. This article guides you through practical, cost-effective solutions—from AI-powered content creation and graphic design to customer insights and streamlined automation. Whether you're looking to boost marketing efforts or optimize workflow with strategic planning, learn how to integrate tools like ChatGPT, Canva, and Trello into your daily processes. Emphasizing a “start small” approach, this post provides actionable tips and a clear roadmap for leveraging AI to enhance productivity, foster innovation, and secure a competitive edge in today’s dynamic market.",
        image: "ai-for-small-business.webp",
        id: 4,
        url: "https://medium.com/@bitblossomsoftware/unlocking-ai-for-small-business-success-tools-you-can-use-today-b59d5bbaaddc",
        alt: "Unlocking AI for Small Business Success",
        date : '03/22/2025'
    },
    {
        title: "From Memorization to Meaning: Testing LLMs with Contrasts",
        abstract: "Explore how leveraging LLMs to generate contrast sets can dramatically enhance the performance of smaller models like google/electra-small-discriminator, leading to better generalization, model distillation, and improved task-specific accuracy.",
        image: "llm-contrast-testing.png",
        id: 3,
        url: "https://medium.com/@bitblossomsoftware/from-memorization-to-meaning-testing-llms-with-contrasts-72b7861bfdd2",
        alt: "From Memorization to Meaning: Testing LLMs with Contrasts",
        date: '10/10/2025'
    },
    {
        title: "Why Updating Your DMARC Record is Essential for Your Business Emails",
        abstract: "If your emails are ending up in your clients’ spam folders, it’s not just frustrating — it’s bad for business. Email deliverability is critical for maintaining trust, closing deals, and ensuring smooth communication. One often-overlooked solution? Updating your DMARC record.",
        image: "dmarc-record-update.png",
        id: 2,
        url: "https://medium.com/@bitblossomsoftware/why-updating-your-dmarc-record-is-essential-for-your-business-emails-8ada7fdb6dc2",
        alt: "Why Updating Your DMARC Record is Essential for Your Business Emails",
        date: '01/04/2025'
    },
    {
        title: "Consulting Beyond Code: How Soft Skills Drive Sustainable IT Solutions",
        abstract: "Discover how active listening, understanding client goals, and proactive communication transform short-term IT fixes into enduring solutions, building stronger client relationships and delivering sustainable, long-term value.",
        image: "consulting-beyond-code.png",
        id: 1,
        url: "https://medium.com/@bitblossomsoftware/consulting-beyond-code-how-soft-skills-drive-sustainable-it-solutions-a6d4249c1135",
        alt: "Consulting Beyond Code",
        date: '12/01/2024'
    },
    {
        title: "Building Subscription Management with Kajabi Webhooks: Lessons Learned",
        abstract: "Learn how to integrate Kajabi’s Offer Purchase and Payment Complete webhooks into a subscription-based web app, tackle unexpected complexities in data handling, and manage subscription status with best practices and code snippets.",
        image: "kajabi-webhook-integration.png",
        id: 0,
        url: "https://medium.com/@bitblossomsoftware/kajabi-webhook-integration-into-a-3rd-party-app-b29449aaddb5",
        alt: "Building Subscription Management with Kajabi Webhooks",
        date: '07/07/2024'
    }
];
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Typography, Link } from '@mui/material';
import AppBarWithLogo from '../components/AppBarWithLogo';
import BottomNav from '../components/BottomNav';

const h1Style = {
  fontSize: {
    xs: '24px',
    sm: '32px',
    md: '40px',
  },
  fontFamily: 'Signika',
  color: 'primary.main',
  lineHeight: {
    xs: 1,
    md: 1.5,
  },
};

const paragraphStyle = {
  fontFamily: 'DM Sans',
  fontSize: {
    xs: '14px',
    sm: '18px',
    md: '20px',
  },
  lineHeight: 1.8,
};

const PrivacyPolicyPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Bit Blossom Software</title>
        <meta
          name="description"
          content="Read the privacy policy for Bit Blossom Software. Learn how we collect, use, and safeguard your information."
        />
        <link rel="canonical" href="https://www.bitblossomsoftware.com/privacy-policy" />
      </Helmet>

      <AppBarWithLogo
        logoAlt="Bit Blossom Software Logo"
        logoSrc="BitBlossom_Logo_Hztl_cropped.png"
        appName="Bit Blossom Software"
        useDark={false}
      />

      <Box sx={{ overflowX: 'hidden' }}>
        <Container maxWidth="md" sx={{ py: 4 }}>
          <Typography variant="h2" component="h1" gutterBottom sx={h1Style}>
            Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph sx={paragraphStyle}>
            Last updated: March 2025
          </Typography>

          <Typography variant="h4" component="h2" gutterBottom sx={h1Style}>
            Introduction
          </Typography>
          <Typography variant="body1" paragraph sx={paragraphStyle}>
            Welcome to Bit Blossom Software. We are committed to protecting your personal information
            and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and
            safeguard your information when you visit our website (https://www.bitblossomsoftware.com),
            including any other media form, mobile website, or mobile application related or connected
            thereto (collectively, the “Site”). Please read this policy carefully. If you do not agree
            with the terms of this Privacy Policy, please do not access the Site.
          </Typography>

          <Typography variant="h4" component="h2" gutterBottom sx={h1Style}>
            Information We Collect
          </Typography>
          <Typography variant="body1" paragraph sx={paragraphStyle}>
            We may collect information about you in a variety of ways. The information we may collect on
            the Site includes:
          </Typography>
          <Box component="ul" sx={paragraphStyle}>
            <li>
              <strong>Personal Data:</strong> Personally identifiable information, such as your name,
              email address, and telephone number, that you voluntarily provide to us when you register
              or contact us.
            </li>
            <li>
              <strong>Usage Data:</strong> Automatically collected information, such as your IP address,
              browser type, operating system, access times, and pages viewed.
            </li>
            <li>
              <strong>Cookies and Similar Technologies:</strong> We use cookies, web beacons, and other
              tracking technologies to enhance your experience and collect information about your usage
              of our Site.
            </li>
          </Box>

          <Typography variant="h4" component="h2" gutterBottom sx={h1Style}>
            How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph sx={paragraphStyle}>
            We use the information we collect to:
          </Typography>
          <Box component="ul" sx={paragraphStyle}>
            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our services</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>Communicate with you, including for customer service and marketing purposes</li>
            <li>Monitor and analyze usage and trends</li>
            <li>Prevent fraud and ensure the security of our site</li>
          </Box>

          <Typography variant="h4" component="h2" gutterBottom sx={h1Style}>
            Disclosure of Your Information
          </Typography>
          <Typography variant="body1" paragraph sx={paragraphStyle}>
            We may share your information in certain situations, including:
          </Typography>
          <Box component="ul" sx={paragraphStyle}>
            <li>
              <strong>By Law or to Protect Rights:</strong> If required by law or to protect our rights.
            </li>
            <li>
              <strong>Third-Party Service Providers:</strong> With vendors who perform services on our
              behalf.
            </li>
            <li>
              <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets.
            </li>
          </Box>

          <Typography variant="h4" component="h2" gutterBottom sx={h1Style}>
            Security of Your Information
          </Typography>
          <Typography variant="body1" paragraph sx={paragraphStyle}>
            We implement a variety of security measures to protect your personal information. However,
            no method of transmission over the Internet or electronic storage is 100% secure.
          </Typography>

          <Typography variant="h4" component="h2" gutterBottom sx={h1Style}>
            Changes to This Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph sx={paragraphStyle}>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting
            the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for
            any changes.
          </Typography>

          <Typography variant="h4" component="h2" gutterBottom sx={h1Style}>
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph sx={paragraphStyle}>
            If you have any questions about this Privacy Policy, please contact us:
          </Typography>
          <Typography variant="body1" paragraph sx={paragraphStyle}>
            Email: <Link href="mailto:hello@bitblossomsoftware.com">hello@bitblossomsoftware.com</Link>
            <br />
            Phone: 701-390-3503
            <br />
            Address: Fargo, North Dakota
          </Typography>
        </Container>
      </Box>

      <BottomNav useDark={true} />
    </>
  );
};

export default PrivacyPolicyPage;

import React from "react";
import { Organization } from "schema-dts";
import { JsonLd } from "react-schemaorg";

export const organizationData: Organization & { "@context": "https://schema.org" } = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Bit Blossom Software",
  url: "https://www.bitblossomsoftware.com",
  logo: "https://www.bitblossomsoftware.com/BitBlossom_Logo_Hztl_cropped.png",
  contactPoint: {
    "@type": "ContactPoint",
    telephone: "701-390-3503",
    contactType: "Customer Service",
    email: "hello@bitblossomsoftware.com",
    areaServed: "US",
    availableLanguage: "English"
  },
  potentialAction: {
    "@type": "InteractAction",
    target: "https://www.bitblossomsoftware.com/contact",
    name: "Contact Us"
  },
  address: {
    "@type": "PostalAddress",
    addressLocality: "Fargo",
    addressRegion: "ND",
    addressCountry: "US"
  },
  sameAs: [
    "https://www.facebook.com/share/1D7Gs6mkDP/",
    "https://www.linkedin.com/in/sydney-boschert-67074749",
    "https://medium.com/@bitblossomsoftware",
    "https://www.instagram.com/bitblossomsoftware/",
    "https://github.com/sydneyboschert",
    "https://g.co/kgs/QWWPxVZ"
  ]
};

export const GetOrganizationMeta = () => {
  return (
    <>
      {/* Organization structured data */}
      <JsonLd<Organization> item={organizationData} />
    </>
  );
};

import React from 'react';
import { Helmet } from 'react-helmet-async';
import AppBarWithLogo from '../components/AppBarWithLogo';
import BottomNav from '../components/BottomNav';
import { Box, Typography } from '@mui/material';
import ReasonsSection from '../components/AboutComponenets/ReasonsSection';
import SydneySection from '../components/AboutComponenets/SydneySection';
import BehindTechSection from '../components/AboutComponenets/BehindTheTechSection';
import LetsGetStartedSection from '../components/AboutComponenets/LetsGetStartedSection';
import AboutUsQuotes from '../components/AboutComponenets/AboutUsQuotes';
import { organizationData } from "../data/meta";
import { JsonLd } from 'react-schemaorg';
import { AboutPage, Person } from 'schema-dts';

const About: React.FC = () => {
  const h1Style = {
    fontSize: {
      xs: '24px',
      sm: '32px',
      md: '40px',
    },
    fontFamily: 'Signika',
    color: 'primary.main',
    lineHeight: {
      xs: 1,
      md: 1.5,
    },
  };

  const paragraphStyle = {
    fontFamily: 'DM Sans',
    fontSize: {
      xs: '14px',
      sm: '18px',
      md: '20px',
    },
    lineHeight: 1.8,
  };

  return (
    <>
      <Helmet>
        <title>About Us | Bit Blossom Software</title>
        <meta name="description" content="Bit Blossom Software provides heart-centered software solutions in Fargo, ND. Empowering businesses with compassionate, tailored technology solutions." />
        <link rel="canonical" href="https://www.bitblossomsoftware.com/about" />
      </Helmet>

      {/* JSON‑LD for the About Page */}
      <JsonLd<AboutPage>
        item={{
          "@context": "https://schema.org",
          "@type": "AboutPage",
          name: "About Us | Bit Blossom Software",
          url: "https://www.bitblossomsoftware.com/about",
          description:
            "Bit Blossom Software provides heart-centered software solutions in Fargo, ND. Empowering businesses with compassionate, tailored technology solutions.",
          mainEntity: organizationData,
        }}
      />

      {/* JSON‑LD for the Founder (Sydney) using info from AboutSydneySection */}
      <JsonLd<Person>
        item={{
          "@context": "https://schema.org",
          "@type": "Person",
          name: "Sydney Boschert",
          url: "https://www.bitblossomsoftware.com/about",
          image: "https://www.bitblossomsoftware.com/AboutSydney.jpg", // Ensure this URL points to the correct image
          jobTitle: "Founder & Lead Developer",
          description:
            "After years of helping businesses navigate their tech needs, I turned my passion for problem-solving into a business built on connection and trust. Whether it’s crafting stunning websites, building custom software, or offering IT guidance, I’m dedicated to making technology accessible and empowering. I believe in doing work that matters and living a life we love—one filled with meaningful projects, time with family and friends, and a commitment to supporting businesses. I’m here to help you simplify technology so you can focus on growing your business and chasing your dreams.",
          worksFor: organizationData,
          sameAs: [
            "https://www.facebook.com/share/1D7Gs6mkDP/",
            "https://www.linkedin.com/in/sydney-boschert-67074749",
            "https://medium.com/@bitblossomsoftware",
            "https://www.instagram.com/bitblossomsoftware/",
            "https://github.com/sydneyboschert",
            "https://g.co/kgs/QWWPxVZ"
          ],
        }}
      />

      <AppBarWithLogo
        logoAlt="Bit Blossom Software Logo"
        logoSrc={`BitBlossom_Logo_Hztl_cropped.png`}
        appName="Bit Blossom Software"
        useDark={false}
      />
      <Box sx={{ overflowX: 'hidden' }}>
        <h1 style={{ display: 'none' }}>About</h1>
        {/* Empowering Businesses Section */}
        <Box
          sx={{
            position: 'relative',
            overflow: 'visible',
            width: '100%',
            px: { xs: 2, md: 10 },
            py: { xs: 4, md: 8 },
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'secondary.main',
              opacity: 0.1,
              zIndex: -1,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              zIndex: 1,
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <Typography component='h1' variant="h1" sx={{ ...h1Style, color: 'primary.main', fontSize: { sm: 20, md: 60 },  mb:1 }}>
              About
            </Typography>
                        <Typography
                            component="h2"
                            variant="h2"
                            sx={{
                                textAlign: 'left',
                                ...paragraphStyle,
                                color: 'primary.main',
                                opacity: 0.8,
                            }}
                        >
              Empowering Businesses with Heart-Centered Software Solutions
            </Typography>
          </Box>
        </Box>
        {/* Sydney Section (AboutSydneySection) displays the founder info visually */}
        <SydneySection h1Style={h1Style} paragraphStyle={paragraphStyle} />
        {/* Tech Made Simple Section */}
        <AboutUsQuotes h1Style={h1Style} paragraphStyle={paragraphStyle} />
        {/* Reasons in Your Corner Section */}
        <ReasonsSection h1Style={h1Style} paragraphStyle={paragraphStyle} />
        <BehindTechSection h1Style={h1Style} paragraphStyle={paragraphStyle} />
        <LetsGetStartedSection h1Style={h1Style} paragraphStyle={paragraphStyle} />
        <BottomNav useDark={true} />
      </Box>
    </>
  );
};

export default About;

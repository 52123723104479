import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import ContactForm from '../ContactForm';

interface ContactUsSectionProps {
    ref?: React.Ref<HTMLDivElement>;
}

const h1Style = {
    fontSize: {
        xs: '24px',
        sm: '32px',
        md: '40px',
    },
    fontFamily: 'Signika',
    color: 'primary.main',
};

const ContactUsSection = React.forwardRef<HTMLDivElement, ContactUsSectionProps>((props, ref) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Box
            ref={ref}
            sx={{
                position: 'relative',
                width: '100%',
                mt: isMobile ? 10 : 10,
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',

                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: 'url("ContactUsBackground.jpg")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.6,
                    zIndex: 1,
                },
            }}
        >
            {/* Overlay Container (positioned above background) */}
            <Box
                sx={{
                    mx: 'auto',
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    p: { xs: 2, md: 5 },
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: { xs: '100%', md: '80%' },
                        height: { xs: '100%', md: '60%' },
                        bgcolor: 'white',
                        display: 'flex',
                        flexDirection: 'row',
                        p: { xs: 2, md: 5 },
                        zIndex: 2,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {/* Left Column: Image on top of the background */}
                    <Box
                        component="img"
                        src="ContactUsSydneyPic.jpg"
                        alt="Sydney Founder"
                        sx={{
                            width: '40%',
                            height: 'auto',
                            objectFit: 'cover',
                            display: { xs: 'none', md: 'block' },
                        }}
                    />
                    <Box
                        sx={{
                            ml: { md: 5 },
                            mt: { xs: 3, md: 0 },
                            width: '100%',
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        {/* Row 1: Title */}
                        <Typography variant="h1" color="primary.main" sx={{ ...h1Style, pb: 2.5 }}>
                            Lets Work Together
                        </Typography>

                        {/* Row 2: Subtitle */}
                        <Typography
                            variant="subtitle1"
                            color="primary.main"
                            sx={{
                                fontFamily: 'DM Sans',
                                fontSize: 20,
                                fontWeight: 'Medium',
                                pb: 2,
                                opacity: '80%',
                                lineHeight: 1.3,
                                mb: 2,
                            }}
                        >
                            I’m excited to hear from you! Whether you’re ready to start a new project or just want connect and create something amazing together.
                        </Typography>
                        <ContactForm />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
});

export default ContactUsSection;
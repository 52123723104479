import React from 'react';
import { Helmet } from 'react-helmet-async';
import AppBarWithLogo from '../components/AppBarWithLogo';
import BottomNav from '../components/BottomNav';
import { Box, Typography } from '@mui/material';
import { Blogs } from '../data/Blogs';
import BlogItem from '../components/BlogItem';
import { JsonLd } from 'react-schemaorg';
import { Blog, BlogPosting } from 'schema-dts';
import { GetOrganizationMeta } from '../data/meta';

const h1Style = {
    fontSize: {
        xs: '24px',
        sm: '32px',
        md: '60px',
    },
    fontFamily: 'Signika',
    color: 'primary.main',
    lineHeight: {
        xs: 1,
        md: 1.5,
    },
};

const paragraphStyle = {
    fontFamily: 'DM Sans',
    fontSize: {
        xs: '14px',
        sm: '18px',
        md: '20px',
    },
    lineHeight: 1.8,
};

const BlogPage: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Blog | Bit Blossom Software</title>
                <meta 
                  name="description" 
                  content="Read the latest insights, updates, and stories from Bit Blossom Software in Fargo, ND. Stay informed about our software solutions and technology trends." 
                />
                <link rel="canonical" href="https://www.bitblossomsoftware.com/blog" />
            </Helmet>
            {GetOrganizationMeta()}
            <JsonLd<Blog>
                item={{
                    "@context": "https://schema.org",
                    "@type": "Blog",
                    name: "Bit Blossom Software Blog",
                    url: "https://www.bitblossomsoftware.com/blog",
                    blogPost: Blogs.map((blog): BlogPosting => ({
                        "@type": "BlogPosting",
                        headline: blog.title,
                        image: blog.image,
                        url: blog.url,
                        description: blog.abstract,
                        datePublished: blog.date,
                        author: {
                          "@type": "Person",
                          name: "Sydney Boschert"
                        }
                        // Optionally, add additional properties like datePublished, publisher, etc.
                    })),
                }}
            />

            <AppBarWithLogo
                logoAlt="Bit Blossom Software Logo"
                logoSrc={`BitBlossom_Logo_Hztl_cropped.png`}
                appName="Bit Blossom Software"
                useDark={false}
            />
            <Box sx={{ overflowX: 'hidden' }}>
                {/* Empowering Businesses Section */}
                <Box style={{ position: 'relative', overflow: 'visible', width: '100%' }}>
                    <Box
                        component="img"
                        src="BlogTopImage.png"
                        alt="Home Page Top Banner"
                        sx={{
                            display: 'block',
                            width: '100%',
                            height: 'auto',
                            objectFit: 'cover',
                        }}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            display: 'flex',
                            top: 0,
                            left: 0,
                            width: { xs: '100%', md: '50%' },
                            height: '100%',
                            flexDirection: 'column',
                            mt: { xs: 0, md: 10 },
                            ml: { xs: 2, md: 10 },
                            pt: { xs: 2, md: 0 },
                            color: 'white',
                        }}
                    >
                        <Typography
                            component="p"
                            variant="h1"
                            sx={{
                                textAlign: 'left',
                                mb: 1,
                                ...h1Style
                            }}
                        >
                            Blog
                        </Typography>
                        <Typography
                            component="p"
                            variant="h2"
                            sx={{
                                textAlign: 'left',
                                ...paragraphStyle,
                                color: 'primary.main',
                                opacity: 0.8,
                            }}
                        >
                            Explore the Latest Tech Articles & Insights
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        borderTop: '1px solid',
                        borderColor: 'rgba(255, 255, 255, 0.3)',
                        mt: 4,
                        mb: 0,
                    }}
                />
                <Box sx={{ mt: 4, width: '100%' }}>
                    {Blogs.map((blog, index) => (
                        <BlogItem
                            key={blog.id}
                            title={blog.title}
                            picture={blog.image}
                            abstract={blog.abstract}
                            url={blog.url}
                            bottom_boarder={index === Blogs.length - 1 ? false : true}
                        />
                    ))}
                </Box>
                <BottomNav useDark={true} />
            </Box>
        </>
    );
};

export default BlogPage;

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Home from './pages/Home';
import About from './pages/About';
import WebDesignServicesPage from './pages/WebDesignServicesPage';
import Contact from './pages/Contact';
import ReactGA from 'react-ga4';
import { CssBaseline } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import CustomSoftwareServicesPage from "./pages/CustomSoftwareServicesPage";
import ITTechSupportPage from "./pages/ITTechSupportPage";
import AIConsultingServicesPage from "./pages/AIPage";
import { createBreakpoints } from "@mui/system";
import BlogPage from "./pages/Blog";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      tertiary: string;
    };
  }
  interface PaletteOptions {
    custom?: {
      tertiary?: string;
    };
  }
}


const App: React.FC = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const breakpoints = createBreakpoints({});

  let theme = createTheme({
    palette: {
      primary: {
        main: '#5C007C' // #Royal_Violet
      },
      secondary: {
        main: '#CB77FF', //Fuschia
      },
      custom: {
        tertiary: '#004752', // Evergreen Third primary color
      },
      common: {
        //white: '#EFECE5',
        white: '#FAF8FE',
        black: '#000000',
      },
    },
    typography: {
      fontFamily: 'DM Sans, sans-serif',
      h1: {
        fontFamily: 'Signika',
        color: 'primary.main',
        fontWeight: 400, // Regular
        [breakpoints.down("md")]: {
          fontSize: '40px',
          lineHeight : 1.5
        },
        [breakpoints.down("sm")]: {
          fontSize: '32px'
        },
        [breakpoints.down("xs")]: {
          fontSize: '24px',
          lineHeight :1
        },
      },
      h2: {
        fontFamily: 'DM Sans, sans-serif',
        fontWeight: 800, // Extra bold
        fontSize: isMobile ? '1rem' : '2.12rem', // Adjust font size for mobile
      },
      h3: {
        fontFamily: 'DM Sans, sans-serif',
        fontWeight: 700, // Bold
        fontSize: isMobile ? '0.875rem' : '1.75rem', // Adjust font size for mobile
      },
      h4: {
        fontFamily: 'DM Sans, sans-serif',
        fontWeight: 600, // Semi-bold
        fontSize: isMobile ? '0.75rem' : '1.5rem', // Adjust font size for mobile
      },
      h5: {
        fontFamily: 'DM Sans, sans-serif',
        fontWeight: 500, // Medium
        fontSize: isMobile ? '0.625rem' : '1.25rem', // Adjust font size for mobile
      },
      h6: {
        fontFamily: 'DM Sans, sans-serif',
        fontWeight: 400, // Regular
        fontSize: isMobile ? '0.5rem' : '1rem', // Adjust font size for mobile
      },
      body1: {
        fontFamily: 'DM Sans',
        [breakpoints.down("md")]: {
          fontSize: '20px',
          lineHeight : 1.8
        },
        [breakpoints.down("sm")]: {
          fontSize: '18px'
        },
        [breakpoints.down("xs")]: {
          fontSize: '14px',
        },
      },
    },
  });

  theme = responsiveFontSizes(theme);

  const TRACKING_ID = "G-VLYP8LFK6Y";
  ReactGA.initialize(TRACKING_ID);

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/web-development" element={<Navigate to="/services/web-development" replace />} />
            <Route path="/custom-software" element={<Navigate to="/services/custom-software" replace />} />
            <Route path="/it-tech-support" element={<Navigate to="/services/it-tech-support" replace />} />
            <Route path="/services/web-development" element={<WebDesignServicesPage />} />
            <Route path="/services/custom-software" element={<CustomSoftwareServicesPage />} />
            <Route path="/services/it-tech-support" element={<ITTechSupportPage />} />
            <Route path="/services/ai-consulting" element={<AIConsultingServicesPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
import React from 'react';
import { Box, Typography, Link, useMediaQuery, useTheme } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { SocialMedia } from '../data/SocialMediaLinks';
import { useWindowSize } from '@uidotdev/usehooks';

interface BottomNavProps {
  useDark: boolean;
}

interface BottomNavPicType {
  altText: string;
  imageName: string;
  objectPosition: string;
}

const BottomNavPictures: BottomNavPicType[] = [
  {
    altText: "Desktop Setup",
    imageName: '/BitBlossomSoftware-DeskSetup-Headphones.jpg',
    objectPosition: 'bottom right'
  },
  {
    altText: "Glasses on Desktop",
    imageName: "/ContactUsBackground.jpg",
    objectPosition: 'center'
  },
  {
    altText: "Stickers",
    imageName: "/ShowStickers.jpg",
    objectPosition: 'center'
  },
  {
    altText: "Phone & Stickers",
    imageName: "/PhoneStickers.jpg",
    objectPosition: 'bottom right'
  },
  {
    altText: "Typing Code",
    imageName: "/TypingCode.jpg",
    objectPosition: 'right'
  },
];

const CustomBottomNavigation: React.FC<BottomNavProps> = ({ useDark }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:600px)');
  const textColor = useDark ? theme.palette.common.white : theme.palette.primary.dark;
  const size = useWindowSize();
  const LineDiv: React.FC<{ mt: number; mb: number }> = ({ mt, mb }) => (
    <Box
      sx={{
        mt: mt,
        mb: mb,
        mx: { xs: 0, md: 6 },
        height: '1px',
        alignSelf: 'stretch',
        backgroundColor: theme.palette.secondary.main,
      }}
    />
  );

  const pictureSize = size.width ? size.width / BottomNavPictures.length : 100;

  return (
    <footer>
      <Box
        sx={{
          width: '100%',
          borderTop: '1px solid rgba(255, 255, 255, 0.3)',
          backgroundColor: useDark ? theme.palette.primary.main : 'transparent',
          '@media (min-width: 400px)': {
            flexDirection: 'column',
            height: 'auto',
            alignItems: 'center',
          },
        }}
      >
        {!isMobile && (<LineDiv mt={5} mb={0} />)}
        <Box
          sx={{
            width: '100%',
            mt: 4,
            mb: 4,
            // 4 equal columns on desktop, 1 column on mobile
            gridTemplateColumns: isMobile ? '1fr' : 'repeat(4, 1fr)',

            // Spacing between columns & rows
            gap: 2, // or columnGap: 2, rowGap: 2, etc.

            // Horizontal padding on the entire grid
            px: { xs: 2, md: 8 },

            display: 'grid',
            justifyContent: 'space-between',
          }}
        >
          {/* Menu Section */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: isMobile ? 'center' : 'flex-start',
              mb: 0,
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color={theme.palette.secondary.main}
            >
              MENU
            </Typography>
            {['About', 'Blog', 'Contact'].map((menu, index) => (
              <Link title={menu} key={index} href={`/${menu.toLowerCase()}`} color="inherit" underline="none">
                <Typography variant="subtitle1" color={textColor} sx={{ textAlign: isMobile ? 'center' : 'left' }}>
                  {menu}
                </Typography>
              </Link>
            ))}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: isMobile ? 'center' : 'flex-start',
              mb: 0,
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color={theme.palette.secondary.main}
            >
              SERVICES
            </Typography>
            {['Web Development', 'Custom Software', 'IT + Tech Support'].map((menu, index) => (
              <Link
                title={menu}
                key={index}
                href={`/services/${menu === 'IT + Tech Support' ? 'it-tech-support' : menu.toLowerCase().replace(/\s+/g, '-')}`}
                color="inherit"
                underline="none"
              >
                <Typography variant="subtitle1" color={textColor} sx={{ textAlign: isMobile ? 'center' : 'left' }}>
                  {menu}
                </Typography>
              </Link>
            ))}
          </Box>

          {/* Contact Section */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: isMobile ? 'center' : 'flex-start',
              mb: 4,
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color={theme.palette.secondary.main}
            >
              CONTACT
            </Typography>
            {/* Email */}
            <Link
              href="mailto:hello@bitblossomsoftware.com"
              color={textColor}
              underline="none"
              sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
            >
              <EmailIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              <Typography variant="subtitle1" color={textColor}>
                hello@bitblossomsoftware.com
              </Typography>
            </Link>
            {/* Phone */}
            <Link
              href="tel:7013903503"
              color={textColor}
              underline="none"
              sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
            >
              <PhoneIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              <Typography variant="subtitle1" color={textColor}>
                701-390-3503
              </Typography>
            </Link>
            {/* Location */}
            <Link
              href="https://www.google.com/maps/place/Bit+Blossom+Software/@45.932079,-96.7708456,6z/data=!3m1!4b1!4m6!3m5!1s0x31edaf027236fa1:0xb9b350328b78ea74!8m2!3d45.932079!4d-96.7708456!16s%2Fg%2F11whktxwmp?entry=ttu&g_ep=EgoyMDI1MDMyNC4wIKXMDSoASAFQAw%3D%3D"
              target="_blank"
              rel="noopener"
              color={textColor}
              underline="none"
              sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
            >
              <LocationOnIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              <Typography variant="subtitle1" color={textColor}>
                Fargo, North Dakota
              </Typography>
            </Link>
          </Box>

          {/* Follow Us Section */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: isMobile ? 'center' : 'flex-start',
              mb: 4,
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color={theme.palette.secondary.main}
            >
              FOLLOW US
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start', gap: 0.25 }}>
              {SocialMedia.map((item) => (
                <Link title={item.title} key={item.id} href={item.href} target="_blank" rel="noopener">
                  <item.icon sx={{ color: textColor, fontSize: 35 }} />
                </Link>
              ))}
            </Box>
          </Box>
        </Box>
        {!isMobile && (<LineDiv mt={0} mb={3} />)}
        {!isMobile && (
          <Box
            sx={{
              width: '100%',
              mt: 0,
              mb: 0,
              // Dynamic columns based on the number of pictures
              gridTemplateColumns: isMobile ? '1fr' : `repeat(${BottomNavPictures.length}, 1fr)`,

              // Spacing between columns & rows
              gap: 1, // or columnGap: 2, rowGap: 2, etc.


              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {BottomNavPictures.map((pic, index) => (
              <Box
                key={index}
                sx={{
                  width: `${pictureSize}px`,
                  height: `${pictureSize}px`,
                  overflow: 'hidden',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={`${pic.imageName}`}
                  alt={pic.altText}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: pic.objectPosition,

                  }}
                />
              </Box>
            ))}
          </Box>)}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
            px: { xs: 2, md: 8 },
          }}
        >
          <img
            src="/BitBlossom_Logo_White.PNG"
            alt="Bit Blossom Software Logo"
            style={{ height: isMobile ? '100px' : `${pictureSize / 2.2}px` }}
          />
          <Typography
            variant="body2"
            color={textColor}
            sx={{
              textAlign: isMobile ? 'center' : 'left',
              mt: isMobile ? 2 : 0,
              fontFamily: 'DM Sans',
              fontSize: { sm: 5, md: 18 },
              lineHeight: 1.5,
              maxWidth: { sm: '100%', md: `${pictureSize * 1.75}px` },
            }}
          >
            Bit Blossom Software specializes in web design, custom software, IT consulting, and tech support for businesses in Fargo, ND, and beyond.
          </Typography>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="body2"
            color={textColor}
            sx={{
              textAlign: 'center',
              fontFamily: 'DM Sans',
              fontSize: 16,
              lineHeight: 1.5,
            }}>
            © 2025 Bit Blossom Software, LLC. | <Link href="https://www.bitblossomsoftware.com" color="secondary">Bit Blossom Software</Link> | <Link href="https://www.bitblossomsoftware.com/privacy-policy" color="secondary">Privacy & Terms</Link>
          </Typography>
        </Box>
      </Box>
    </footer>
  );
};

export default CustomBottomNavigation;